import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

// Load custom fonts
const loadFont = (fontName, fontUrl) => {
  const link = document.createElement('link');
  link.href = fontUrl;
  link.rel = 'stylesheet';
  document.head.appendChild(link);
};

loadFont('Roboto', 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);