import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, List, ListItem, ListItemText, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import Navbar from './Navbar';

function ManageReservations() {
  const [reservations, setReservations] = useState([]);
  const toast = React.useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_API_URL}/user-reservations`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res.json())
    .then(data => setReservations(data))
    .catch(error => {
      console.error("Failed to fetch reservations:", error);
    });
  }, []);

  const handleDelete = (reservationId) => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_API_URL}/delete-reservation/${reservationId}`, {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res.json())
    .then(() => {
      setReservations(prev => prev.filter(r => r.id !== reservationId));
      toast.current.show({ severity: 'success', summary: 'Reservation Deleted', detail: 'Your reservation has been deleted.' });
    })
    .catch(error => {
      console.error("Failed to delete reservation:", error);
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete reservation.' });
    });
  };

  return (
    <Container>
        <Navbar />
      <Toast ref={toast} />
      <Typography variant="h4" gutterBottom>Manage Your Reservations</Typography>
      <List>
        {reservations.map((reservation) => (
          <ListItem key={reservation.id} divider>
            <ListItemText
              primary={`Reservation from ${new Date(reservation.start_date).toLocaleDateString()} to ${new Date(reservation.end_date).toLocaleDateString()}`}
              secondary={`People: ${reservation.people}, Stay Type: ${reservation.stay_type}`}
            />
            <IconButton edge="end" color="secondary" onClick={() => handleDelete(reservation.id)}>
              <Delete />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Button variant="contained" color="primary" onClick={() => navigate('/calendar')}>
        Back to Calendar
      </Button>
    </Container>
  );
}

export default ManageReservations;