import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';  // Import the CSS for background changes
import logo from './backgrounds/osbornecabinlogo.png';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [animationClass, setAnimationClass] = useState(''); // Set class for background change

    useEffect(() => {
        // Temporarily override the current month logic for testing seasons
        const testSeason = '';  // Leave blank for current season logic or set to 'fall', 'winter', 'spring', or 'summer' for testing
    
        if (testSeason) {
            setAnimationClass(testSeason);
        } else {
            // Get the current month to determine the season
            const currentMonth = new Date().getMonth();
            let animationClass = '';
            if (currentMonth >= 8 && currentMonth <= 10) { // September - November
                animationClass = 'fall';  // Fall season
            } else if (currentMonth >= 11 || currentMonth <= 3) { // December - March
                animationClass = 'winter';  // Winter season
            } else if (currentMonth >= 4 && currentMonth <= 5) { // April - May
                animationClass = 'spring';  // Spring season
            } else if (currentMonth >= 6 && currentMonth <= 7) { // June - August
                animationClass = 'summer';  // Summer season
            }
            setAnimationClass(animationClass);
        }
    }, []);

    const handleLogin = () => {
        fetch(`${process.env.REACT_APP_API_URL}/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password })
        })
        .then(res => res.json())
        .then(data => {
            if (data.access_token) {
                localStorage.setItem('token', data.access_token);
                navigate('/calendar'); // Redirect to calendar after login
            } else {
                alert('Invalid login credentials');
            }
        });
    };

    return (
        <div className={`login-page ${animationClass}`}>
            <Container maxWidth="sm" className="login-form">
                <img src={logo} style={{ width: 200, height: 240 }} alt="Logo" />
                <Typography variant="h4" gutterBottom>Login</Typography>
                <TextField
                    label="Email"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    label="Password"
                    type="password"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleLogin}
                >
                    Login
                </Button>
                <Typography align="center" style={{ marginTop: '20px' }}>
                    New user?{' '}
                    <Link component="button" variant="body2" onClick={() => navigate('/register')}>
                        Register here
                    </Link>
                </Typography>
            </Container>
        </div>
    );
}

export default LoginPage;