import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography } from '@mui/material';
import Navbar from './Navbar';

function UserSettings() {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_API_URL}/user-settings`, {
            headers: { 'Authorization': `Bearer ${token}` }
        })
        .then(res => res.json())
        .then(data => {
            setEmail(data.email);
            setName(data.name);
        });
    }, []);

    const handleSave = () => {
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_API_URL}/user-settings`, {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` 
            },
            body: JSON.stringify({ email, name, password })
        })
        .then(res => res.json())
        .then(() => alert('Settings updated successfully'));
    };

    return (
        <Container>
            <Navbar />
            <Typography variant="h4" gutterBottom>User Settings</Typography>
            <TextField label="Email" fullWidth margin="normal" value={email} onChange={(e) => setEmail(e.target.value)} />
            <TextField label="Name" fullWidth margin="normal" value={name} onChange={(e) => setName(e.target.value)} />
            <TextField label="Password" type="password" fullWidth margin="normal" value={password} onChange={(e) => setPassword(e.target.value)} />
            <Button variant="contained" color="primary" fullWidth onClick={handleSave}>Save</Button>
        </Container>
    );
}

export default UserSettings;