import React, { useEffect, useState } from 'react';
import { Container, Button } from '@mui/material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import Navbar from './Navbar';


function BlogPage() {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();
  const [expandedRows, setExpandedRows] = useState(null);
  const [filters, setFilters] = useState(null);
  const [authors, setAuthors] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      user: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      post_type: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
  
    _filters['global'].value = value;
  
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const [postTypes, setPostTypes] = useState([
    { label: 'Guide', value: 'Guide' },
    { label: 'PSA', value: 'PSA' },
    { label: 'Journal', value: 'Journal' },
    { label: 'Update', value: 'Update' },
    { label: 'Other', value: 'Other' }
]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_API_URL}/posts`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(res => res.json())
      .then(data => {
        setPosts(data);
        const uniqueAuthors = [...new Set(data.map(post => post.user))];
        setAuthors(uniqueAuthors);
        initFilters();  // Initialize filters after data is loaded
      });
  }, []);

  const titleBodyTemplate = (rowData) => {
    return (
      <div>
        <h3>{rowData.title}</h3>
      </div>
    );
  };

  const rowExpansionTemplate = (rowData) => {
    return (
        <div className="p-3">
            <div dangerouslySetInnerHTML={{ __html: rowData.body }} />
            {rowData.image_data && (
                <img 
                    src={`${process.env.REACT_APP_API_URL}/posts/${rowData.id}/image`} 
                    alt={rowData.title} 
                    style={{ maxWidth: '100%' }} 
                />
            )}
        </div>
    );
};

const authorFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={authors.map(author => ({ label: author, value: author }))}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder="Select an Author"
        className="p-column-filter"
        showClear
      />
    );
  };

  const postTypeFilterTemplate = (options) => {
    return (
        <Dropdown
            value={options.value}
            options={postTypes}
            onChange={(e) => options.filterCallback(e.value)}
            placeholder="Select a Type"
            className="p-column-filter"
            showClear
        />
    );
};

const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    }).format(date);
};

const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.timestamp);
};

  const onRowToggle = (e) => {
    setExpandedRows(e.data);
  };

  const clearFilter = () => {
    initFilters();
  };
  
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)} placeholder="Keyword Search" />
        </span>
      </div>
    );
  };

  return (
    <Container>
        <Navbar />
      <Button variant="contained" color="primary" onClick={() => navigate('/add-post')}>
        +
      </Button>
      <DataTable
        value={posts}
        sortField='timestamp'
        sortOrder={-1}
        expandedRows={expandedRows}
        onRowToggle={onRowToggle}
        onRowExpand={() => {}}
        onRowCollapse={() => {}}
        rowExpansionTemplate={rowExpansionTemplate}
        filters={filters}
        globalFilterFields={['title', 'user']}
        header={renderHeader()}
        emptyMessage="No posts found."
        onFilter={(e) => setFilters(e.filters)}
      >
        <Column expander style={{ width: '3em' }} />
        <Column field="title" header="Title" body={titleBodyTemplate} />
        <Column field="user" header="Author" filter filterField="user" filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '14rem' }} showFilterMatchModes={false} showFilterOperator={false} filterElement={authorFilterTemplate}/>
        <Column sortable field="timestamp" header="Date & Time" body={dateBodyTemplate}/>
        <Column field="post_type" header="Type" sortable filter
        filterField="post_type"
        showFilterMatchModes={false}
        showFilterOperator={false}
        filterMenuStyle={{ width: '14rem' }}
        style={{ minWidth: '14rem' }}
        filterElement={postTypeFilterTemplate}/>
        
        
      </DataTable>
    </Container>
  );
}

export default BlogPage;