import React, { useState } from 'react';
import { Container, Button, TextField } from '@mui/material';
import { Editor } from 'primereact/editor';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import Navbar from './Navbar';

function AddPostPage() {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const toast = React.useRef(null);
  const [postType, setPostType] = useState('Other');

  const handleSubmit = () => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('title', title);
    formData.append('body', body);
    formData.append('post_type', postType);
    if (image) {
      formData.append('image', image);
    }

    fetch(`${process.env.REACT_APP_API_URL}/posts`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: formData
    })
      .then(res => res.json())
      .then(() => {
        toast.current.show({ severity: 'success', summary: 'Post Added', detail: 'Your post has been added successfully.' });
        setTimeout(() => navigate('/blog'), 2000);
      });
  };

  return (
    <Container>
        <Navbar />
      <Toast ref={toast} />
      <h2>Create a New Post</h2>
      <TextField
        label="Title"
        fullWidth
        margin="normal"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <Editor
        style={{ height: '320px' }}
        value={body}
        onTextChange={(e) => setBody(e.htmlValue)}
      />
      <input
        type="file"
        accept="image/*"
        onChange={(e) => setImage(e.target.files[0])}
        style={{ marginTop: '20px' }}
      />
      <Dropdown
        value={postType}
        options={[
            { label: 'Guide', value: 'Guide' },
            { label: 'PSA', value: 'PSA' },
            { label: 'Journal', value: 'Journal' },
            { label: 'Update', value: 'Update' },
            { label: 'Other', value: 'Other' }
        ]}
        onChange={(e) => setPostType(e.value)}
        placeholder="Select a Post Type"
        className="p-field p-dropdown"  // Optional: you can style it as needed
        />
      <Button variant="contained" color="primary" fullWidth onClick={handleSubmit} style={{ marginTop: '20px' }}>
        Submit Post
      </Button>
    </Container>
  );
}

export default AddPostPage;