import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import CalendarPage from './pages/CalendarPage';
import ReservationPage from './pages/ReservationPage';
import UserSettingsPage from './pages/UserSettingsPage';
import ManageReservations from './pages/ManageReservations';
import BlogPage from './pages/BlogPage';
import AddPostPage from './pages/AddPostPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/calendar" element={<CalendarPage />} />
        <Route path="/reservations" element={<ReservationPage />} />
        <Route path="/user-settings" element={<UserSettingsPage />} />
        <Route path="/manage-reservations" element={<ManageReservations />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/add-post" element={<AddPostPage />} />
      </Routes>
    </Router>
  );
}

export default App;
