import React, { useState, useEffect } from 'react';
import { Button, Container, Typography, MenuItem, Select, FormControl, InputLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Navbar from './Navbar';

function ReservationPage() {
  const [dateRange, setDateRange] = useState(null);
  const [people, setPeople] = useState('');
  const [stayType, setStayType] = useState('open');
  const [unavailableDates, setUnavailableDates] = useState([]);
  const toast = React.useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_API_URL}/calendar`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res.json())
    .then(data => {
      const privateDates = data
        .filter(reservation => reservation.stay_type === 'private')
        .flatMap(reservation => {
          const dates = [];
          let currentDate = new Date(reservation.start_date);
          while (currentDate <= new Date(reservation.end_date)) {
            // Push the date directly without any additional time manipulation
            dates.push(new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate()));
            currentDate.setDate(currentDate.getDate() + 1);
          }
          return dates;
        });
      setUnavailableDates(privateDates);
    });
  }, []);

  const handleReservation = () => {
    if (!dateRange || dateRange.length !== 2) {
      toast.current.show({ severity: 'warn', summary: 'Invalid Date Range', detail: 'Please select both a start and end date.' });
      return;
    }

    const [startDate, endDate] = dateRange;
    const isInvalidRange = unavailableDates.some(date => date >= startDate && date <= endDate);

    if (isInvalidRange) {
      toast.current.show({ severity: 'error', summary: 'Invalid Date Selection', detail: 'The selected date range includes unavailable dates.' });
      return;
    }

    const token = localStorage.getItem('token');
    const reservationData = {
      startDate,
      endDate,
      people,
      stayType,
    };

    fetch(`${process.env.REACT_APP_API_URL}/reservations`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(reservationData)
    })
    .then(res => res.json())
    .then(() => {
      toast.current.show({ severity: 'success', summary: 'Reservation Successful', detail: 'Your reservation has been made.' });
      setTimeout(() => navigate('/calendar'), 2000);
    });
  };

  return (
    <Container maxWidth="sm">
      <Navbar />
      <Toast ref={toast} />
      <Typography variant="h4" gutterBottom>Make a Reservation</Typography>
      
      <FormControl fullWidth margin="normal">
        <Typography variant="h6">Select Date Range</Typography>
        <Calendar
          value={dateRange}
          onChange={(e) => setDateRange(e.value)}
          selectionMode="range"
          readOnlyInput
          placeholder="Select a date range"
          disabledDates={unavailableDates}
          invalid={!dateRange || dateRange.length !== 2}
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel>Number of People</InputLabel>
        <Select
          value={people}
          onChange={(e) => setPeople(e.target.value)}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
            <MenuItem key={num} value={num}>{num}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl component="fieldset" margin="normal">
        <Typography variant="h6">Stay Type</Typography>
        <RadioGroup
          row
          value={stayType}
          onChange={(e) => setStayType(e.target.value)}
        >
          <FormControlLabel value="open" control={<Radio />} label="Open" />
          <FormControlLabel value="private" control={<Radio />} label="Private" />
        </RadioGroup>
      </FormControl>

      <Button variant="contained" color="primary" fullWidth onClick={handleReservation}>Submit Reservation</Button>
    </Container>
  );
}

export default ReservationPage;