import React from 'react';
import { Menubar } from 'primereact/menubar';
import { useNavigate } from 'react-router-dom';

function Navbar() {
  const navigate = useNavigate();

  const items = [
    {
      label: 'Home',
      icon: 'pi pi-home',
      command: () => navigate('/calendar')  // Navigate to the calendar page
    },
    {
      label: 'Reservations',
      icon: 'pi pi-calendar-plus',
      command: () => navigate('/reservations')  // Navigate to the reservations page
    },
    {
      label: 'User Settings',
      icon: 'pi pi-cog',
      command: () => navigate('/user-settings')  // Navigate to the user settings page
    },
    {
      label: 'Manage Reservations',
      icon: 'pi pi-list',
      command: () => navigate('/manage-reservations')  // Navigate to the manage reservations page
    },
    {
      label: 'Blog',
      icon: 'pi pi-book',
      command: () => navigate('/blog')  // Navigate to the blog page
    },
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      command: () => handleLogout()  // Logout and navigate to the login page
    }
  ];

  const handleLogout = () => {
    localStorage.removeItem('token'); // Clear the token from local storage
    navigate('/login'); // Redirect to the login page
  };

  return (
    <div className="card">
      <Menubar model={items} />
    </div>
  );
}

export default Navbar;