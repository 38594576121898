import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import Navbar from './Navbar';
import '../App.css';

function CalendarPage({ theme, setTheme }) {
  const [calendarData, setCalendarData] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_API_URL}/calendar`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => res.json())
    .then(data => {
      if (Array.isArray(data)) {
        const events = data.map(reservation => ({
          id: reservation.id,
          title: `${reservation.user} - ${reservation.people} people`,
          start: reservation.start_date.split('T')[0],
          end: new Date(new Date(reservation.end_date).setDate(new Date(reservation.end_date).getDate() + 1)).toISOString().split('T')[0],
          color: reservation.stay_type === 'open' ? 'green' : 'red'
        }));
        setCalendarData(events);
      } else {
        console.error("Expected array but received:", data);
        setCalendarData([]);
      }
    })
    .catch(error => {
      console.error("Failed to fetch calendar data:", error);
      setCalendarData([]);
    });
  }, []);

  const handleEventClick = (info) => {
    const reservation = calendarData.find(event => event.id === info.event.id);
    setSelectedReservation(reservation);
  };

  const handleClose = () => {
    setSelectedReservation(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className={`calendar-page ${theme}`}>
      <Navbar theme={theme} setTheme={setTheme} />
      <Container style={{ flexGrow: 1, overflowY: 'auto', paddingBottom: '20px' }}>
        <Typography variant="h4" gutterBottom>Calendar</Typography>
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={calendarData}
          eventClick={handleEventClick}
          displayEventTime={false}
        />
        <Button variant="contained" onClick={() => navigate('/reservations')}>Make a Reservation</Button>
        <Button variant="contained" onClick={() => navigate('/user-settings')}>User Settings</Button>
        <Button variant="contained" onClick={() => navigate('/manage-reservations')}>Manage Reservations</Button>
        <Button variant="contained" onClick={() => navigate('/blog')}>Go to Blog</Button>
        <Button variant="contained" onClick={handleLogout}>Logout</Button>
        {selectedReservation && (
          <Dialog open={true} onClose={handleClose}>
            <DialogTitle>Reservation Details</DialogTitle>
            <DialogContent>
              <Typography>User: {selectedReservation.title.split(' - ')[0]}</Typography>
              <Typography>Number of People: {selectedReservation.title.split(' - ')[1].split(' ')[0]}</Typography>
              <Typography>Start Date: {new Date(selectedReservation.start).toLocaleDateString()}</Typography>
              <Typography>End Date: {new Date(selectedReservation.end).toLocaleDateString()}</Typography>
              <Typography>Stay Type: {selectedReservation.color === 'green' ? 'Open' : 'Private'}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">Close</Button>
            </DialogActions>
          </Dialog>
        )}
      </Container>
    </div>
  );
}

export default CalendarPage;